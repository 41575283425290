<template>
  <v-select
    v-model="selectedTimbrado"
    :items="timbrados"
    :loading="loadingTimbrado"
    item-text="timbrado"
    item-value="timbrado_id"
    hide-details
    outlined
    dense
    :label="$tc('global.timbrado')"
  >
    <template v-slot:selection="{ item }">
      <span>{{
        `${item.descricao ? item.descricao : ""} ${item.timbrado} `
      }}</span>
    </template>
    <template v-slot:item="{ item }">
      <span>{{
        `${item.descricao ? item.descricao : ""} ${item.timbrado} `
      }}</span>
    </template></v-select
  >
</template>

<script>
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
export default {
  name: "SelectTimbrado",

  props: {
    timbrado_sync: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loadingTimbrado: true,
      selectedTimbrado: null,
      timbrados: [],
    };
  },

  watch: {
    selectedTimbrado() {
      if (this.selectedTimbrado) {
        this.$emit("update:timbrado_sync", this.selectedTimbrado);
      }
    },
  },

  methods: {
    async getTimbrados() {
      this.loadingTimbrado = true;

      return fetchUserTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.loadingTimbrado = false;
        })
        .catch((error) => {
          this.loadingTimbrado = false;
        });
    },
  },

  mounted() {
    this.getTimbrados();
  },
};
</script>

<style></style>
